
<template>
	<section class="">
		<h1>
			<img src="/img/payment/qcm-icon.svg" alt="" />QCM<img
				class="info"
				@click="showGuide"
				src="/img/info-bulle.svg"
				alt=""
			/>
		</h1>
		<filter-wrapper
			@updateFilter="updateFilter"
			:showFilter="false"
		/>
		
		<div class="link-container">
			<div class="link-wrapper">
				<button id="question" @click="showPackage(false)" :class="!isPackageVisible ? 'active' : ''" >Choix d'une question</button>
				<button id="key" @click="showPackage(true)" :class="isPackageVisible ? 'active' : ''" >Choix d'un QCM clé en main</button>

				<span class="underline" :style="'width:' + spanWidth + 'px;left:' + spanPosition + 'px;'"
				></span>
			</div>
			<div class="btn-container">
				<router-link class="submit dark" :to="{name:'MyResourcesAddQcm'}">✏️ Créer une question</router-link>
				<router-link class="submit dark" :to="{name:'GenerateQCM'}">✨ Générer une question</router-link>
			</div>
		</div>
		<div class="qcm-container">
			<Loader v-if="!isLoaded" />
			<div v-if="!isPackageVisible">
				<QCMQuestionContainer
					v-for="question in questions"
					:key="question.id"
					:question="question"
					:added="isAdded(question.id)"
					@addQuestion="addQuestion"
				/>
			</div>
			<div v-else>
				<QCMContainer v-for="(qcmPackage, index) in packages" :key="index"
					:qcmPackage="qcmPackage" @addQuestion="addQuestion" :selectedQuestions="selectedQuestions" />
			</div>
		</div>
		<pagination-component
			class="question-paginate"
			ref="paginate"
			v-if="pages !== null && pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="changeFilter"
		/>

		<div
			:class="selectedQuestions.length > 0 ? 'active' : ''"
			class="footer-validation"
		>
			<button @click="resetQuestions">Reinitialiser</button>
			<button @click="showPopUpSave">
				Enregistrer
				<span
					>&nbsp; ( {{ selectedQuestions.length }} question<span
						v-if="selectedQuestions.length > 1"
						>s
					</span>
					)
				</span>
			</button>
		</div>
		<Teleport to="body">
			<PopUpConfigureQCM
				v-if="isPopUpSaveVisible"
				@close="showPopUpSave"
				:questions="selectedQuestions"
				@removeQuestion="addQuestion"
			/>
		</Teleport>
	</section>
</template>

<script>
import FilterWrapper from '../FilterWrapper.vue'
import {filteredQCMQuestionsService} from '../../../../services/qcmQuestionsServices'
import {filteredQCMQuestionPackageService} from '../../../../services/qcmQuestionPackagesServices'
import QCMQuestionContainer from './QCMQuestionContainer.vue'
import QCMContainer from './QCMContainer.vue'
import PaginationComponent from '../../../Utils/PaginationComponent.vue'
import Loader from '../../../Loader.vue'
import PopUpConfigureQCM from '../../../PopUp/QCM/PopUpConfigureQCM.vue'
import { checkRightFor } from '../../../../utils'

export default {
	components: { FilterWrapper, QCMQuestionContainer, PaginationComponent,Loader, PopUpConfigureQCM,QCMContainer },
	data(){
		return {
			questions: [],			
			filter: {
				chapter: null,
				level: null,
				subject: null,
				search: '',
				offset: 0,
			},
			currentPage: 0,
			pages: null,
			count: null,
			actualFilter: '',
			isLoaded: false,
			selectedQuestions: [],
			isPopUpSaveVisible: false,
			isPackageVisible: false,
			packages: [],
			questionWidth: 0,
			keyWidth: 0,
			questionPosition: 0,
			keyPosition: 0,
			spanWidth: 0,
			spanPosition: 0,
			isSubmit: false
		}
	},
	methods:{
		showPopUpSave(){
			this.isPopUpSaveVisible = !this.isPopUpSaveVisible
		},

		showPackage(value){
			this.isPackageVisible = value

			if(this.isPackageVisible){
				this.spanWidth = this.keyWidth
				this.spanPosition = this.keyPosition
				this.getPackage()
			}
			else{
				this.spanWidth = this.questionWidth
				this.spanPosition = this.questionPosition
				this.getQCM()
			}

		},
		async getPackage(){
			this.questions = []
			this.packages = []
			this.isLoaded = false
			const res = await filteredQCMQuestionPackageService(this.actualFilter)
			this.packages = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
			this.isLoaded = true

		},
		async getQCM(){
			this.packages = []
			this.isLoaded = false
			const res = await filteredQCMQuestionsService(this.actualFilter)
			this.questions = res.results
			this.checkRight()
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
			this.isLoaded = true
		},

		checkRight(){
			if(!checkRightFor('QCM', [])){
				this.questions.map((item,index) => {
					if(index > 4) item.disabled = true
				})

			}
		},
		
		resetQuestions() {
			this.selectedQuestions = []
		},
		addQuestion(question) {
			const isAlreadyAdded = this.selectedQuestions.find(x => x.id == question.id)
			if (isAlreadyAdded) {
				this.selectedQuestions = this.selectedQuestions.filter(x => x.id !== question.id)
			}
			else {
				this.selectedQuestions.push(question)
			}
		},
		updateFilter(data) {
			this.filter.level = data.level
			this.filter.chapter = data.chapter
			this.filter.search = data.search
			this.filter.teacher = data.teacher
			this.filter.subject = data.subject
			this.changeFilter()
		},
		changeFilter(e) {
			const search = this.filter.search ? `&search=${this.filter.search}` : '&search='
			const chapter = this.filter.chapter ? `chapter=${this.filter.chapter}` : 'chapter='
			const level = this.filter.level ? `&level=${this.filter.level}` : '&level='
			const teacher = this.filter.teacher ? `&teacher=${this.filter.teacher}` : ''
			const subject = this.filter.subject ? `&subject=${this.filter.subject}` : ''
			if (typeof e == 'number') {

				this.filter.offset = 20 * e
				this.currentPage = e
			}
			else {
				this.currentPage = 0
				this.filter.offset = 0
			}
			
			
			if(this.isPackageVisible){
				this.actualFilter = `?${chapter}${level}${subject}${teacher}&page=${this.currentPage + 1}${search}`
				this.getPackage()
			}
			else{
				this.actualFilter = `?${chapter}${level}${subject}${teacher}&offset=${this.filter.offset}${search}`
				this.getQCM()
			}
		},
		isAdded(e) {
			let question = this.selectedQuestions.find(x => x.id == e)
			if (question)
				question = true
			else
				question = false
			return question
		},
	},	

	
	mounted () {
		const question = document.getElementById('question')
		const key = document.getElementById('key')
		this.questionWidth = question.offsetWidth
		this.keyWidth = key.offsetWidth
		

		this.questionPosition = question.getBoundingClientRect().left - question.getBoundingClientRect().left
		this.keyPosition = key.getBoundingClientRect().left - question.getBoundingClientRect().left
		
		this.spanWidth = this.questionWidth
		
	},
	watch: {
		isPackageVisible(){
			this.currentPage = 0
		},
		'$route': {
			handler(newValue) {
				if (newValue.name !== 'GenerateQCM' && newValue.name !== 'MyResourcesAddQcm') {
					this.selectedQuestions = []
					this.$store.state.selectedQCMQuestions = []
				}
			},
		}
	},

	created() {
		if (this.$store.state.selectedQCMQuestions){
			this.selectedQuestions = this.$store.state.selectedQCMQuestions
		}
	},
	unmounted() {
		if(this.selectedQuestions.length > 0){
			this.$store.state.selectedQCMQuestions = this.selectedQuestions
		}
	},
	
		
}
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/admin/qcm/qcm.scss';
</style>
