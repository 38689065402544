<template>
	<div :class="isAllQuestionsAdded ? 'active' : ''" class="qcm-wrapper">
		<div class="header-container">
			
			<div class="text-container">
				<p>{{ clonedQcmPackage.title }}</p>
				
			</div>
			<button @click="addAllQuestions" class="submit add" :class="isAllQuestionsAdded ? 'added' : ''">
				<span v-if="isAllQuestionsAdded"> - Supprimer</span>
				<span v-else> + Ajouter</span>
			</button>
		</div>
		<div class="question-container" @click="toggleDropDown">
			
			<p><span style="color: #3732AF;">{{ clonedQcmPackage.questions.length }} questions</span>  - Réalisé par <span style="color: #3732AF;">{{ clonedQcmPackage.teacher }}</span> </p>
			<div class="img-container" :class="isDropDownVisible ? 'active' : ''">
				<img src="/img/down-arrow.svg" alt="">
			</div>
		</div>
		<div class="question-wrapper" :class="isDropDownVisible ? 'active' : ''">
			<div>
				<div v-for="question in clonedQcmPackage.questions_data" :key="question.id" >
					<QCMQuestionContainer :question="question" 
						@addQuestion="add(question)" :added="isAdded(question.id)" :showImg="isDropDownVisible"/>
				</div>
			</div>
		</div>
		<Teleport to="body">
			<pop-up-preview-qcm-question v-if="isPopUpPreviewQcmVisible" :question="question" @close="showPopUpPreviewQcm"/>
		</Teleport>
	</div>
	
</template>

<script>
import { getQCMQuestionPackageService } from '../../../../services/qcmQuestionPackagesServices'
import { checkRightFor } from '../../../../utils'
import PopUpPreviewQcmQuestion from '../../../PopUp/QCM/PopUpPreviewQcmQuestion.vue'
import QCMQuestionContainer from './QCMQuestionContainer.vue'

export default {
	components: { PopUpPreviewQcmQuestion, QCMQuestionContainer },
	
	props: {
		qcmPackage: Object,	
		selectedQuestions: Array
	},
	data(){
		return {
			isPopUpPreviewQcmVisible: false,
			isDropDownVisible: false,
			clonedQcmPackage: {}
		}
	},

	methods:{
		isAdded(questionId){
			return this.selectedQuestions.some(x => x.id === questionId)
		},
		toggleDropDown(){
			if(!checkRightFor('QCM', [])){
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			this.isDropDownVisible = !this.isDropDownVisible
		},
		addAllQuestions() {
			if(!checkRightFor('QCM', [])){
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			if(this.isAllQuestionsAdded){
				for(const question of this.clonedQcmPackage.questions_data){
					this.$emit('addQuestion', question)
				}
				return
			}

			for(const question of this.clonedQcmPackage.questions_data){
				if(!this.isAdded(question.id)){
					this.$emit('addQuestion', question)
				}
			}
		},
		add(question) {
			this.$emit('addQuestion', question)
		},
		color(e) {
			let color
			let width
			switch (e) {
				case 'Facile':
					color = '#09E300'
					width = '33%'
					break
				case 'Moyen':
					color = '#FF9300'
					width = '66%'
					break
				case 'Difficile':
					color = '#FF0000'
					width = '100%'
					break
				default:
					break
			}
			return `background-color: ${color};width: ${width};`
		},
		
		showPopUpPreviewQcm(){
			this.isPopUpPreviewQcmVisible = !this.isPopUpPreviewQcmVisible
		},
		async getQcmPackage(){
			const res = await getQCMQuestionPackageService(this.qcmPackage.id)
			this.clonedQcmPackage.questions_data = res.questions_data
		}
	},
	created(){
		this.clonedQcmPackage = JSON.parse(JSON.stringify(this.qcmPackage))
		this.getQcmPackage()
	},
	computed: {
		isAllQuestionsAdded() {
			if (!this.clonedQcmPackage.questions_data) return false
			const questionsIds = this.clonedQcmPackage.questions_data.map(x => x.id)
			const selectedQuestionsIds = this.selectedQuestions.map(x => x.id)
			return questionsIds.every(x => selectedQuestionsIds.includes(x))
		},
		
	},
	
	
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/admin/qcm/qcm-container.scss';
</style>
