<template>
	<pop-up-wrapper>
		<div class="pop-up-configure-qcm-sheet">
			<img
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
				class="close"
			/>
			<h2>Création du QCM</h2>
			<div class="pop-up-content-container">
				<div class="field-container">
					<div class="input-container">
						<label for="name">Titre *</label>
						<input
							class="form-control required"
							id="name"
							v-model="form.title"
							placeholder="Nom de la ressource"
							type="text"
							@change="updateError"
						/>
						<small class="error">{{ errors.title }}</small>
					</div>
					<div class="input-container">
						<label for="classes">Classe *</label>
						<select
							class="required"
							id="classes"
							@change="updateErrorClasse"
							v-model="form.classroom"
						>
							<option
								v-for="classe in $store.state.classes"
								:key="classe.id"
								:value="classe.id"
							>
								{{ classe.name }}
							</option>
						</select>
						<small class="error">{{ errors.classe }}</small>
					</div>
					<div class="input-container">
						<label for="date">Créneau</label>
						<select v-model="form.currency" id="date">
							<option
								v-for="course in getCourses"
								:key="course.id"
								:value="course.id"
							>
								{{ course.formatedDay }}
								{{ course.debut_hour }} -
								{{ course.end_hour }}
							</option>
						</select>
					</div>
					<div class="input-container">
						<label for="duration">Durée (en minute)</label>
						<input v-model="form.duration" type="number" min="1">
					</div>
					<div class="input-container">
						<div class="private-mode">
							<label for="private">Mode privé</label>
							<input id="private" v-model="privateMode" type="checkbox">
						</div>
						<p class="explication" >Votre QCM sera accessible uniquement par vous. Il ne sera pas soumis à une étape de vérification pour faire partie de la banque de QCM publique</p>
					</div>
				</div>
				<div class="exo-container">
					<p>Questions</p>
					<div class="exo-wrapper">
						<draggable
							v-if="organizedQuestions.length > 0"
							:list="organizedQuestions"
							:disabled="!enabled"
							item-key="name"
							class="exo"
							ghost-class="ghost"
							@start="dragging = true"
							@end="dragging = false"
						>
							<template #item="{ element }">
								<div
									class="drag-el img-container"
									:class="{ 'not-draggable': !enabled }"
								>
									<div
										class="img-arrow-container"
										
									>
										<p v-html="element.question"></p>
										<label class="point-amount" for="">Nombre de points
										<input  type="number" @change="updateQuestionPoint($event,element.id)" value="1" min="0"></label>
										<p v-for="answer in element.answers" :key="answer.id">
										<span :style="`color: ${answer.is_correct ? '#47cf52' : '#C22A29'} `" v-html="answer.answer">	</span></p>
										
										<img
											@click="removeQuestion(element)"
											class="remove"
											src="/img/blue-cross.svg"
											alt=""
										/>
									</div>
								</div>
							</template>
						</draggable>
					</div>
				</div>
			</div>
			<button @click="submit" class="submit dark">Enregistrer</button>
		</div>
		<Teleport to="body">
			<full-page-loader v-if="loading" />
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../config'
import { formateAndOrderDate } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
import draggable from "vuedraggable"
import { allActivityEventsFilterService } from '../../../services/activityEventsService'
import { createQCMService } from '../../../services/qcmServices'
import { createQCMQuestionOrderService } from '../../../services/qcmQuestionsOrderServices'
import { createQcmQuestionPointService } from '../../../services/qcmQuestionPointsServices'
import FullPageLoader from '../../Utils/FullPageLoader.vue'
import { getOccurenceByIdService, updateOccurenceService } from '../../../services/occurrencesService'
import { createQCMQuestionPackageService } from '../../../services/qcmQuestionPackagesServices'

export default {
	components: {
		draggable,
		PopUpWrapper,
		FullPageLoader,
	},
	props: {
		questionLength: Number,
		questions: Array
	},
	data() {
		return {
			url: Config.backend_url,
			dragging: false,
			enabled: true,
			form: {
				title: '',
				classroom: [],
				currency: null,
				duration: 10
			},
			privateMode: false,
			classes: [],
			errors: {},
			courses: [],
			organizedQuestions: [],
			loading: false,
			questionPoints: []
		}
	},

	methods: {
		updateQuestionPoint(event,id) {
			const point = event.target.value
			if(this.questionPoints.find(x => x.id === id)) {
				this.questionPoints = this.questionPoints.map(x => x.id === id ? {id,point} : x)
				return
			}	
			this.questionPoints.push({id,point})
			
		},
		removeQuestion(e) {
			this.$emit('removeQuestion', e)
			this.organizedQuestions = this.organizedQuestions.filter(x => x.id !== e.id)
		},
		
		async allCourses() {
			let classes = []

			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}
			if (this.$store.state.selectedOccurrence) {
				this.form.classroom = this.$store.state.selectedOccurrence.classId
				const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
				this.form.currency = occurrenceId
			}

		},
		updateInput() {
			this.errors.title = null
		},
		updateSelect() {
			this.errors.classe = null
		},
		close() {
			this.$emit('closed')
		},
		checkErrors() {
			let errors = false
			if (this.form.title == '') {
				this.errors.title = 'Veuillez ajouter un nom à votre ressource'
				errors = true
			}
			if (this.form.classroom.length == 0) {
				this.errors.classe = 'Veuillez ajouter une classe à votre ressource'
				errors = true
			}
			return errors
		},
		async submit() {
			if(this.checkErrors()) return 
			this.loading = true
			const res = await createQCMService(this.form)
			for (const question of this.organizedQuestions) {
				const data = {
					question: question.id,
					qcm: res.id,
					order: this.organizedQuestions.indexOf(question)
				}
				await createQCMQuestionOrderService(data)
			}
			for(const question of this.questionPoints) {
				const data = {
					question: question.id,
					qcm: res.id,
					point: question.point
				}
				await createQcmQuestionPointService(data)
			}
			if (this.form.currency == undefined) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(this.form.currency)
				occurrence.qcms.push(res.id)

				await updateOccurenceService(this.form.currency, occurrence)

				let patchedOccurence = this.courses.find(e => {
					return e.qcms.find(x => x == res.id)
				})
				if (patchedOccurence && this.oldCurrency !== this.form.currency) {
					patchedOccurence.qcms = patchedOccurence.qcms.filter(x => x !== res.id)
					await updateOccurenceService(patchedOccurence.id, patchedOccurence)
				}
			}
			if(!this.privateMode){
				const qcmQuestionPackage = {
					title: this.form.title,
					questions: this.organizedQuestions.map(x => x.id),
				}
				await createQCMQuestionPackageService(qcmQuestionPackage)
			}
			
			this.loading = false
			this.$router.push({ name: 'ClassResources', params: { id: this.form.classroom } })
		},
		
	},

	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classroom)
			return courses
		}
	},

	created() {
		this.allCourses()
		this.organizedQuestions = [...this.questions]

	},



}
</script>

<style>
</style>
